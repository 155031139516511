$(document).foundation();

$(document).ready(function () {
    // utils
	window['initVerticalCenter']();
	window['initAnimateInView']();
	window['inlineSVGs']();

	// elements
	window['initDropdownNav']();
	window['initHeader']();
	window['initAccordions']();
	// window['initMaps']();
	window['initSlider']();
	window['initLandingSlider']();
	window['initFooterCarousel']();
	window['initFeaturedCarousel']();
	window['initDetail']();
	window['initOtherRooms']();
	window['initForms']();
	window['initTerms']();
	window['initGallery']();
	window['initVirtualTours']();
	window['initTreatments']();
	window['initAccommodation']();
	window['initOffCanvas']();
	window['initPopup']();
	window['initLogoCarousel']();
	window['initHome']();
	window['initAccommodationSlider']();
	window['initAccommodationListSlider']();
	window['initAccommodationListResidencesSlider']();
	window['initMenusSlider']();
	window['initTestimonialSlider']();
	window['customCookieBar']();
	window['initLazyLoading']();
	window['initCustomFilter']();
});




$(document).ready( function () {

	$('#book-now-accordion').accordiom({
		speed: 500,
		showFirstItem: false,
		openAll: false,
		autoClosing: true
	});


	$( '#book-now-mobile .header__open-booking' ).click(function() {
		$('.book-now-mobile-content').toggle();
		$('#book-now-mobile .header__open-booking').toggleClass('on');
	});



});





// Define the default settings to be used in the project
// These can be overridden in each sites settings.js file
var defaultSettings = {
	slider: {
		speed: 3500,
		animationSpeed: 750,
		arrows: true,
		pagination: false,
		paginationArrows: true
	},
	sliderFullHeightScrollButton: false,
	landingSlider: {
		autoplay: false,
		animationSpeed: 750,
		arrows: true,
		pagination: false,
		paginationArrows: false
	},
	footerCarousel: {
		slidesToShow: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2
				}
			}
		]
	},
	homeSlider: {
		fade: true,
		arrows: true
	},
	logoCarousel: {
		slidesToShow: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2
				}
			}
		]
	},
	otherRoomsCarousel: {
		arrows: false
	},
	featuredCarouselSingleImage: {
		arrows: true,
		dots: true,
		slidesToShow: 3,
		centerMode: true,
		centerPadding: '0px',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	},
	featuredCarouselDoubleImage: {
		arrows: true,
		dots: true,
		slidesToShow: 1
	},
	accommodationSlider: {
		arrows: true,
		dots: false
	},
	accommodationListSlider: {
		arrows: true,
		dots: false
	},
	accommodationListResidencesSlider: {
		arrows: true,
		dots: false
	},
	menusSlider: {
		arrows: true,
		dots: false
	},
	accordion: {},
	inlineSVG: [
        '.footer__social img',
		'.header__mobile-call img'
    ],
	mapStyle: [
		{
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#f5f5f5"
			}
		  ]
		},
		{
		  "elementType": "labels.icon",
		  "stylers": [
			{
			  "visibility": "off"
			}
		  ]
		},
		{
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#616161"
			}
		  ]
		},
		{
		  "elementType": "labels.text.stroke",
		  "stylers": [
			{
			  "color": "#f5f5f5"
			}
		  ]
		},
		{
		  "featureType": "administrative.land_parcel",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#bdbdbd"
			}
		  ]
		},
		{
		  "featureType": "poi",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#eeeeee"
			}
		  ]
		},
		{
		  "featureType": "poi",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#757575"
			}
		  ]
		},
		{
		  "featureType": "poi.park",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#e5e5e5"
			}
		  ]
		},
		{
		  "featureType": "poi.park",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#9e9e9e"
			}
		  ]
		},
		{
		  "featureType": "road",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#ffffff"
			}
		  ]
		},
		{
		  "featureType": "road.arterial",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#757575"
			}
		  ]
		},
		{
		  "featureType": "road.highway",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#dadada"
			}
		  ]
		},
		{
		  "featureType": "road.highway",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#616161"
			}
		  ]
		},
		{
		  "featureType": "road.local",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#9e9e9e"
			}
		  ]
		},
		{
		  "featureType": "transit.line",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#e5e5e5"
			}
		  ]
		},
		{
		  "featureType": "transit.station",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#eeeeee"
			}
		  ]
		},
		{
		  "featureType": "water",
		  "elementType": "geometry",
		  "stylers": [
			{
			  "color": "#c9c9c9"
			}
		  ]
		},
		{
		  "featureType": "water",
		  "elementType": "labels.text.fill",
		  "stylers": [
			{
			  "color": "#9e9e9e"
			}
		  ]
		}
	  ]
}
var settings;
if (typeof projectSettings === 'undefined') {
	settings = defaultSettings;
} else {
	// Update the default settings with the custom ones
	settings = $.extend(true,{}, defaultSettings, projectSettings);
}






var initVerticalCenter = function () {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
var inlineSVGs = function () {
    var selectorString = '';
    if (typeof settings.inlineSVG === "object") {
        selectorString = settings.inlineSVG.join(',');
    } else {
        selectorString = settings.inlineSVG;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if ((UrlExists(imgURL)) && (imgURL.indexOf('.svg') > -1)) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');

	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }

	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');

	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }

	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}

function UrlExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status!=404;
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}





var wrapPrice = function (findIn, wrapBefore, wrapAfter) {
	// finds price strings like £100, or $400.50
	return findIn.replace(/(((£|\$|€)([0-9]*)([.|,])([0-9]*)|(£|\$|€)([0-9]*)))/g, wrapBefore + '$1' + wrapAfter);
}






/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}




function pushContentBelowHeader() {
	$('main').css('padding-top', $('.header').outerHeight() + 0);
}



var initSlider = function () {
	$('.slider__inner').reformSlider(
		settings.slider
	);

	if ($('.slider__video').length > 0) {
		// Make sure the slider always fills the whole screen
		coverViewport($('.slider__video iframe'), true);
		$(window).on('resize', function () {
			coverViewport($('.slider__video iframe'), true);
		});
	}

	// Add a button for users to scroll down to the content below
	if (settings.sliderFullHeightScrollButton) {
		if ($('.slider').hasClass('slider--full-height')) {
			$('.slider').append('<div class="slider__scroll"><button>Scroll</button></div>');
		}
		$('.slider__scroll button').on('click', function () {
			$('html, body').animate({
				scrollTop: $('.slider').outerHeight() - $('.header').outerHeight()
			}, 1000);

			return false;
		});
	}

	// If there's no slider, make sure that the content starts below the header
	if ($('.slider').length === 0) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	}

	//Ticket 14626 start

	if ( $('.slider__slide .slider__video').length > 0 ) {
		var iframePlayer = $('iframe#vimeo-player');
		
		// Null check for iframePlayer
		if (iframePlayer.length > 0) {
			var player = new Vimeo.Player(iframePlayer[0]);
	
			// Null check for player
			if (player) {
				if (navigator.userAgent.indexOf('Mobile') > -1) {
					// On mobile the autoplay event detection isn't firing, so just go ahead and hide the placeholder
					setTimeout(function() {
						$('.video-placeholder').fadeOut();
					}, 4000);
				} else {
					player.on('play', function(data) {
						setTimeout(function() {
							$('.video-placeholder').fadeOut();
						}, 500); // "The Safari Delay"
					});
				}
			} else {
				console.error('Vimeo player could not be initialized.');
			}
		} else {
			console.error('No iframe with the ID "vimeo-player" found.');
		}
	}

	//Ticket 14626 end
}


var initLandingSlider = function () {
	$('.landing__slider-inner').reformSlider(
		settings.landingSlider
	);
}



var initFooterCarousel = function () {
	$('.footer__logo-carousel').slick(settings.footerCarousel);
}

var initFeaturedCarousel = function () {
	$('.featured-carousel--has-scrollbar').append('<div class="featured-carousel__scrollbar"><div class="featured-carousel__scrollbar-thumb"></div></div>');

	var dragStarted = false;
	var dragStartMousePosition = 0;
	var dragStartThumbPosition = 0;
	var dragDistance = 0;

	$('.featured-carousel--has-scrollbar .featured-carousel__inner').on('init', function (event, slick) {
		setScrollbarThumbSize(this);
		$(window).on('resize', function () {
			setScrollbarThumbSize(this);
		});

		$carousel       = $(this);
		$scrollbar      = $(this).parent().find('.featured-carousel__scrollbar');
		$scrollbarThumb = $(this).parent().find('.featured-carousel__scrollbar-thumb');

		$scrollbarThumb.on('mousedown touchstart', function (event) {
			// Drag has started, so note the mouse starting postiion
			dragStarted = true;
			dragStartMousePosition = event.clientX;
			dragDistance = 0;
			$scrollbarThumb.css('transition-duration', '0s');
			dragStartThumbPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];
		});

		$(window).on('mouseup touchend', function (event) {
			if (!dragStarted) {
				return;
			}
			// Finished dragging, so clear temp vals and move the thumb to it's correct position
			dragStarted = false;
			$scrollbarThumb.css('transition-duration', '0.3s');

			var slideCount = $carousel.parent().find('.slick-dots li').length;
			var scrollbarWidth = $carousel.parent().find('.featured-carousel__scrollbar').outerWidth();
			var thumbWidth = scrollbarWidth / slideCount;
			var dragEndPosition = $scrollbarThumb.css('transform').split(/[()]/)[1].split(',')[4];

			var dragEndIndex = Math.floor((dragEndPosition / scrollbarWidth) * 10);
			if (dragEndIndex < 0) {
				dragEndIndex = 0;
			} else if (dragEndIndex >= slideCount) {
				dragEndIndex = slideCount-1;
			}

			// finally tell slick what slide to change to
			$carousel.slick('slickGoTo', dragEndIndex, false);
		});

		$(window).on('mousemove touchmove', function (event) {
			if (dragStarted) {
				dragDistance = event.clientX - dragStartMousePosition;
				var newPosition = (event.clientX - dragStartMousePosition) + dragStartThumbPosition*1;

				// Stop it from dragging outside the scrollbar
				if ((newPosition >= 0) && (newPosition < ($scrollbar.outerWidth() - $scrollbarThumb.outerWidth()))) {
					$scrollbarThumb.css('transform', 'translateX(' + (dragDistance + dragStartThumbPosition *1) + 'px)');
				}
			}
		});
	});

	$('.featured-carousel--has-scrollbar .featured-carousel__inner').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		setScrollbarThumbPosition(this, nextSlide);
	});

	$('.featured-carousel--single-image .featured-carousel__inner').slick(settings.featuredCarouselSingleImage);
	$('.featured-carousel--double-image .featured-carousel__inner').slick(settings.featuredCarouselDoubleImage);

	function setScrollbarThumbSize(carousel) {
		// Divide the width of the scrollbar by the number of slides to get the width of the thumb
		var slideCount = $(carousel).parent().find('.slick-dots li').length;
		var scrollbarWidth = $(carousel).parent().find('.featured-carousel__scrollbar').outerWidth();
		var thumbWidth = scrollbarWidth / slideCount;
		$(carousel).parent().find('.featured-carousel__scrollbar-thumb').css('width', thumbWidth);
	}

	function setScrollbarThumbPosition(carousel, nextSlideIndex) {
		// Move the thumb to the correct notch for the current slide index
		var slideIndex;
		if (typeof nextSlideIndex === 'undefined') {
			slideIndex = nextSlideIndex;
		} else {
			slideIndex = $(carousel).slick('slickCurrentSlide');
		}
		var scrollbarThumbWidth = $(carousel).parent().find('.featured-carousel__scrollbar-thumb').outerWidth();
		var thumbPosition = nextSlideIndex * scrollbarThumbWidth;
		$(carousel).parent().find('.featured-carousel__scrollbar-thumb').css('transform', 'translateX(' + thumbPosition + 'px)');
	}
}



function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		if ( $(this).hasClass('in-view') ) {
			return;
		}

		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;

			$(elementToAnimate).addClass('in-view'); // log that this element has been seen


			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
			}, 300);


			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;

	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}








var initDropdownNav = function () {
	$('.header__nav > ul > li').on('mouseenter', function (e) {
		if ($('html').attr('data-whatintent') === 'mouse') {
			$(this).find('.header__sub-nav').addClass('open');
		}
	});

	$('.header__nav > ul > li').on('mouseleave', function (e) {
		if ($('html').attr('data-whatintent') === 'mouse') {
			$(this).find('.header__sub-nav').removeClass('open');
		}
	});

	$('.header__nav > ul > li').on('touchstart', function (e) {
		var $dropdown = $(this).find('.header__sub-nav');
		if ( $dropdown.hasClass('open') ) {
			return; // menu has been pressed second time so allow to navigate there
		} else {
			$('.header__sub-nav.open').removeClass('open');
			$dropdown.addClass('open');
			e.preventDefault();
		}
	});
}




var initHeader = function () {

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;

	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	function setHeaderTransparency() {
		if ( $('.slider').length > 0 ) {

			if (window.pageYOffset < 150) {
				$('.header').addClass('header--transparent');
			} else {
				$('.header').removeClass('header--transparent');
			}

		}
	}

	// Show the burger nav when there isn't enough room for the horizontal nav
	if ( $('.header__horizontal-nav').length > 0 ) {
		$(window).on('resize', function () {
			console.log('resizing1');
			throttle(function (event) {
				console.log('resizing2');
			}, 1500);
		});
	}
}



var initAccordions = function () {
	$('.accordion__inner').accordiom(settings.accordion);
}


// function initMaps() {
// 	$('[data-map]').each(function () {
// 		buildMap(this);
// 	});
// }


var buildMap = function (mapContainer) {
	var $mapContainer = $(mapContainer);
	$mapContainer.each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;

		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}

		zoom = $(this).data('map-zoom') || 7;
		if( $(window).outerWidth() < 700 ) {
			zoom = 10;
		} else if( ($(window).outerWidth() >=700) && ($(window).outerWidth() < 1200) ) {
			zoom = 11;
		}

		if ((typeof settings.mapStyle !== 'undefined') || (settings.mapStyle === false))  {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,

				styles: settings.mapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}


		if (typeof $(this).data('map-marker-coords') != 'undefined') {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}

			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map,
				icon: {
					url: $(this).data('map-marker-url'),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(54, 0)  // set arow point of marker
				}
			});


		}

		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}

			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});

			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		});

		if (typeof $(this).data('map-directions') != 'undefined') {
			var directionsService  = new google.maps.DirectionsService();
			var directionsRenderer = new google.maps.DirectionsRenderer();
			directionsRenderer.setMap(map);
			directionsRenderer.setPanel($('#map-directions-data').get(0));

			var directionsTo = $(this).data('map-directions-destination');

			$('#frmPostcode').on('submit', function() {
				var directionsFrom = jQuery.trim(jQuery('#tbPostcode').val());
				var directionsRequest = {
					origin: directionsFrom,
					destination: directionsTo,
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.IMPERIAL
				};

				directionsService.route(directionsRequest, function(result, status){
			    	if (status == google.maps.DirectionsStatus.OK) {
						marker.setMap(null);
						directionsRenderer.setDirections(result);
			    	} else {
				    	alert('Location "' + directionsFrom + '" not found.');
			    	}
				});

				return false;
			});
		}
	});
}

var initDetail = function () {
	$('.detail__features-toggle').on('click', function (e) {
		e.preventDefault();

		// Toggle the second list of features
		$(this).prev('.detail__features-list--additional').slideToggle();

		// Toggle the read more/less button text
		if ( $(this).text() === $(this).data('more-text') ) {
			$(this).text($(this).data('less-text'));
		} else {
			$(this).text($(this).data('more-text'));
		}
	});

	// Make sure the second list is visible again when screen size increases to medium
	if ( $('.detail__features').length > 0 ) {
		$(window).on('resize', function () {
			if (Foundation.MediaQuery.atLeast('medium')) {
				$('.detail__features-list--additional').show();
			}
		});

		// Make the price larger
		$('.detail__rate').html(wrapPrice($('.detail__rate').html(), '<big>', '</big>'));
	}

}

var initOtherRooms = function () {
	// Custom nav for the carousel
	$('.other-rooms__nav button').on('click', function () {
		$(this).parents('.other-rooms').find('.other-rooms__carousel').slick('slickGoTo', $(this).data('index'));
	});

	// Update the nav when the slide changes
	$('.other-rooms__carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$(event.target).parents('.other-rooms').find('li.current').removeClass('current');
		$(event.target).parents('.other-rooms').find('li').eq(nextSlide).addClass('current');
	});

	$('.other-rooms__carousel').slick(settings.otherRoomsCarousel);
}



var initForms = function () {
	$('.form').validate();
}



var initTerms = function () {
	$('.terms__content-more').accordiom({
		showFirstItem: false,
		buttonBelowContent: true,
		beforeChange: function () {
			if ( $(this).hasClass('on') ) {
				$(this).text( $(this).data('more-text') );
			} else {
				$(this).text( $(this).data('less-text') );
			}
		}
	});
}


var initVirtualTours = function () {
	// DUPLICATED CODE FROM BELOW GALLERY
	$('.virtual-tours__grid a').on('click', function () {
		console.log("INIT VIRTUAL TOURS CALLED");
		if ( $(this).data('book-link').indexOf('matterport.com') > -1 ) {
			console.log("HAS MATTERPORT LINK");

			// Load a virtual tour into an iframe
			var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-image"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-iframe"><iframe src="{{src}}"></iframe></div></div><div class="gallery__modal-content grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div></div></div>';

			modal = modal.replace('{{src}}', $(this).data('book-link'));
			modal = modal.replace('{{caption}}', $(this).data('title') || '');
		} 
		else {
			console.log("FAILED MATTERPORT CHECK");
		}

		console.log("AFTER IF CONDITION");

		var $modal = modal;

		$('body').append($modal);

		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});

		$('#gallery__modal').foundation('open');

		return false;
	});
}


var initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);

		if ( $(this).data('book-link').indexOf('matterport.com') > -1 ) {
			// Load a virtual tour into an iframe
			var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-image"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-iframe"><iframe src="{{src}}"></iframe></div></div><div class="gallery__modal-content grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div></div></div>';

			modal = modal.replace('{{src}}', $(this).data('book-link'));
			modal = modal.replace('{{caption}}', $(this).data('title') || '');
		} else {
			// Dynamically load the gallery slider into a reveal popup
			var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-image"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';

			modal = modal.replace('{{src}}', $(this).attr('href'));
			modal = modal.replace('{{caption}}', $(this).data('title') || '');

			var cta = $(this).data('description') || '';
			if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
				cta += '<a href="' + $(this).data('book-link') + '" class="button" target="_blank"' + ' data-content="' + ($(this).data('book-title') || 'Book Now')  + '">' + ($(this).data('book-title') || 'Book Now') + '</a>';
			}
			modal = modal.replace('{{cta}}', cta);
		}

		var $modal = modal;

		$('body').append($modal);

		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});

		$('#gallery__modal').foundation('open');


		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});

		return false;
	});

	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
		$('.gallery__item').addClass('no-transition');
	});

	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);

	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true,
			horizontalOrder: true
		});
	};

	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);



	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}



var initTreatments = function () {
	$('.treatments__nav').accordiom({
		showFirstItem: false
	});

	$('.treatments__nav a').on('click', function () {
		$('html, body').animate({
			scrollTop: $('a[name="' + $(this).attr('href').replace('#', '') + '"]' ).offset().top - $('.header').outerHeight() - 20
		}, 1000);

		return false;
	});
}



var initAccommodation = function () {
	$('.accommodation__list-item:odd').addClass('beige-bg');

	$(window).on('resize', function() {
		if( $(window).outerWidth() < 1024 ) {
			$('.accommodation__items').removeClass('active');
			$('.accommodation__items--grid-view').addClass('active');
			$('.accommodation__views-list-button').removeClass('current');
			$('.accommodation__views-grid-button').addClass('current');
		}
	})

	$('.accommodation__views button').on('click', function () {
		if ( $(this).hasClass('current') ) {
			return;
		}

		// Switch the button current states
		$('.accommodation__views button.current').removeClass('current')
		$(this).addClass('current');

		$('.accommodation__items').each(function() {
			$(this).toggleClass('active');
		})

		setTimeout(function() {
			$(window).trigger('resize');
			$('.accommodation__list-image.slick-initialized').slick('slickGoTo', 0, false);
        	$('.accommodation__list-image.slick-initialized').slick('refresh');
			$('.accommodation__grid-image.slick-initialized').slick('slickGoTo', 0, false);
        	$('.accommodation__grid-image.slick-initialized').slick('refresh');
		},200);
	});
}

var initOffCanvas = function () {
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});


	$('.off-canvas__nav-primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();

				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}






var initPopup = function () {
	if ($('#popup').length > 0) {
		var popupDelay = $('#popup').data('popup-delay') || 0;
		var popupKeepHiddenFor = $('#popup').data('popup-keep-hidden-for-n-days') || 0;

		// Only show the popup if the cookie has not been seen, or always show it for testing in either local or remote markup
		if (
			(!Cookies.get('hasSeenPopup')) ||
			(location.hostname.indexOf('localhost') > -1) ||
			(location.hostname.indexOf('projects') > -1)
		) {
			setTimeout(function() {
				$('#popup').foundation('open');

				if (popupKeepHiddenFor > 0) {
					Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
				}
			}, popupDelay);
		}
	}
}

document.addEventListener("DOMContentLoaded", function() {

    var popup = document.querySelector(".popup");

    if (popup) {

		popup.style.opacity = "0";
		popup.style.bottom = "-900px";
		popup.style.transform = "translateX(-900px)";

		setTimeout(function(){
			popup.style.opacity = "1";
			popup.style.bottom = "0px";
			popup.style.transform = "translateX(0px)";
		}, 2000);

    }

});


var initLogoCarousel = function () {
	$('.logo-carousel__inner').slick(settings.logoCarousel);
}

var initHome = function () {
	$('.home__row-slider').slick(settings.homeSlider);
}

var initAccommodationSlider = function () {
	$('.accommodation__grid-image').slick(settings.accommodationSlider);
}

var initAccommodationListSlider = function () {
	$('.accommodation__list-image').slick(settings.accommodationListSlider);
}

var initAccommodationListResidencesSlider = function () {
	$('.accommodation__list-residences-image').slick(settings.accommodationListResidencesSlider);
}

var initMenusSlider = function () {
	$('.menus__image').slick(settings.menusSlider);
}

var initTestimonialSlider = function () {
	$('.testimonial__slider').slick(settings.homeSlider)
}




var customCookieBar = function () {
	if ( $('.cookie-bar').length > 0 ) {
		if ( !Cookies.get('cookies_accepted') ) {
			$('.cookie-bar').fadeIn();
		}


		$('.cookie-bar__accept').on('click', function () {
			Cookies.set('cookies_accepted', 'true', {expires: 30});
			$('.cookie-bar').fadeOut();
		});
	}
}



function initLazyLoading() {
    var lazyImages = Array.from($("[data-style], .lazy > source, .lazy-img"));

    var options = {
        root: null,
        rootMargin: "150px 0px",
        threshold: 0,
      };

    var lazyImageObserver = new IntersectionObserver(callbackObserver, options);


    function presetImgSizeStyles(img) {
        if (
            img.nodeName === "SOURCE" ||
            img.nodeName === "DIV" ||
            img.classList.contains("bg-img") ||
            typeof img.dataset.presets !== "undefined"
          ) {
            return;
          }
      
          img.style.width = "100%";
          img.style.height = "auto";
          img.style.display = "block";
          img.decoding = "async";
      }      
    
    function setImageViewportWidth(img) {
        var windowWidth = window.innerWidth;
        var imgWidth = img.clientWidth;
        var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
        img.sizes = imgViewportWidth;
      }

    function handleImgSrcset(img) {
        setImageViewportWidth(img);
        img.srcset = img.dataset.srcset;
        img.src = img.dataset.src;
        delete img.dataset.srcset;
        delete img.dataset.src;
        lazyImageObserver.unobserve(img);
       
      }

    function handleImgTag(img) {
        img.src = img.dataset.src;
        delete img.dataset.src;
        lazyImageObserver.unobserve(img);
    }  

    function handleBackgroundImg(img) {
        img.style = img.dataset.style; 
        delete img.dataset;
        lazyImageObserver.unobserve(img);
      }

    function handlePictureElement(img) {
        if (img.nextElementSibling.nodeName === "IMG") {
          img.nextElementSibling.src = img.nextElementSibling.dataset.src;
          delete img.nextElementSibling.dataset.src;
        }
        
        img.srcset = img.dataset.srcset;
        delete img.dataset.srcset;
    
        img.parentElement.classList.remove("lazy");
        lazyImageObserver.unobserve(img);
    }  

    function callbackObserver(entries) {
        entries.forEach(function(entry) {
          var img = entry.target;
          
          if (!entry.isIntersecting) return;
    

          if (img.nodeName === "IMG" && typeof img.dataset.srcset !== 'undefined') {
            handleImgSrcset(img);
			return 
          }

          if (img.nodeName === "IMG" && typeof img.dataset.srcset === "undefined") {
            handleImgTag(img); 
			return
          }

          if (img.nodeName === "DIV") {
            handleBackgroundImg(img);
			return
          }

		  if (img.parentElement.nodeName === "PICTURE") {
            handlePictureElement(img);
			return
          }

          lazyImageObserver.unobserve(img);
        });
      }

      lazyImages.forEach(function (img) {
        presetImgSizeStyles(img)
      })
    
      lazyImages.forEach(function (img) {
        lazyImageObserver.observe(img);
      });
}


function initCustomFilter () {
	var smlFilterToggle = $('.filter .mobile-toggle')
    var filterBtnsWrapper = $('.filter__buttons')
    var filterBtns = $('.filter__buttons button')
	

	if ($(window).innerWidth() < 640) {
		var wrapperHeight = getElmentHeight(filterBtnsWrapper[0])
        toggleSmallFilter(filterBtnsWrapper, smlFilterToggle, filterBtns);
        handleCatChange(smlFilterToggle, filterBtns)
    }

	function toggleSmallFilter(wrapper, btn, catBtns) {
        var isActive = false;

        function handleStateChange (state, wrapper, btn) {
            if (!state) {
				wrapper.css('max-height', '0')
				wrapper.css('opacity', 0)
                btn.removeClass('active');
            } else {
				wrapper.css('max-height', wrapperHeight + 'px')
				wrapper.css('opacity', 1)
                btn.addClass('active');
            }
        }

        catBtns.each(function () {
            $(this).on('click', function () {
                isActive = !isActive;
                handleStateChange(isActive, wrapper, btn);
            })
        })

        btn.on('click', function () {
            isActive = !isActive;
            handleStateChange(isActive, wrapper, $(this));
        })
    }

	function handleCatChange(toggleBtn, catBtns) {   
        catBtns.each(function () {
            $(this).on('click', function () {
                toggleBtn.html($(this).text())
            })
        })
    }


	function getElmentHeight (div) {
        var height;
        div.style.maxHeight = 'unset';
        height = div.clientHeight;
		div.style.maxHeight = '0';
        return height
    }
}


$('.large-image-gallery__inner').slick({
	dots: false,
	infinite: true,
	centerMode: true, // Center the middle image
	centerPadding: '0px', // No padding, all images are same size
	slidesToShow: 1, // Show 3 images at a time
	slidesToScroll: 1,
	arrows: true,
	prevArrow: '<button type="button" class="slick-prev">←</button>',
	nextArrow: '<button type="button" class="slick-next">→</button>',
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1, // Only show 1 image on mobile
				centerMode: false // Disable center mode for mobile
			}
		}
	]
});

$(document).ready(function() {

    // Function to apply the transform logic
    function applyTransformations() {
        // Find the element with .slick-center within .large-image-gallery__inner
        $('.large-image-gallery__inner').each(function() {
            var slickCenter = $(this).find('.slick-center');

            // If a .slick-center element is found
            if (slickCenter.length) {
                // Reset transformations on all .large-image-gallery__item elements
                $('.large-image-gallery__item').css('transform', '');

                // Find the element before .slick-center and apply translateX(200px) to .large-image-gallery__item inside it
                slickCenter.prev().find('.large-image-gallery__item').css('transform', 'translateX(200px)');

                // Find the element after .slick-center and apply translateX(-200px) to .large-image-gallery__item inside it
                slickCenter.next().find('.large-image-gallery__item').css('transform', 'translateX(-200px)');
            }
        });
    }

    // Call the function on page load
    applyTransformations();

    // Call the function before the slide changes to reset transforms
    $('.large-image-gallery__inner').on('beforeChange', function(event, slick, currentSlide) {
        // Reset transformations on all .large-image-gallery__item elements
        $('.large-image-gallery__item').css('transform', '');
    });

    // Call the function every time the slide changes
    $('.large-image-gallery__inner').on('afterChange', function(event, slick, currentSlide) {
        applyTransformations();
    });

});

function applyTransform() {
	var elements = document.querySelectorAll(".widget-visible iframe");
	var i;

	if (window.innerWidth < 600) {
		for (i = 0; i < elements.length; i++) {
			elements[i].style.setProperty("transform", "translateY(-35px)", "important");
		}
	} else {
		for (i = 0; i < elements.length; i++) {
			elements[i].style.removeProperty("transform");
		}
	}
}

// Ensure function runs when resizing the window
window.addEventListener("resize", applyTransform);

// Run after DOM loads and wait a short time to ensure iframes are fully loaded
document.addEventListener("DOMContentLoaded", function () {
	setTimeout(function () {
		applyTransform(); // Apply transformation
		window.dispatchEvent(new Event("resize")); // Manually trigger resize event
	}, 2000); // Short delay (100ms) to ensure elements are rendered
});
